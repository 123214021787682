// extracted by mini-css-extract-plugin
export var chevron = "home-module--chevron--NaQNl";
export var container = "home-module--container--UUTlP";
export var explore = "home-module--explore--OCxwz";
export var footerContainer = "home-module--footerContainer--yMh9O";
export var footerText = "home-module--footerText--LN8mr";
export var link = "home-module--link--FtgKw";
export var plantsContainer = "home-module--plantsContainer--UR-nl";
export var subtitle = "home-module--subtitle--EjOF9";
export var subtitleContainer = "home-module--subtitleContainer--OAOgA";
export var title = "home-module--title--YXpKz";
export var titleContainer = "home-module--titleContainer--hM044";