import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ title, description, image, article, plant }) => {

  const { pathname } = useLocation()
  const { site } = useStaticQuery(graphql`
    query Seo {
      site {
        siteMetadata {
          siteUrl
          twitterUsername
        }
      }
    }
  `)

  const {
    siteUrl,  // Path to the image placed in the 'static' folder, in the project's root directory.
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    websiteName: 'Il Giardino delle Driadi',
    title: `${title} | Il Giardino delle Driadi`,
    description: description,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname}`,
    autore: 'Francesco Leoni'
  }

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {seo.url && <link rel="canonical" href={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {(article ? true : null) && <meta property="article:published_time" content={new Date().toISOString()} />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && <meta property="og:description" content={seo.description} />}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && <meta name="twitter:description" content={seo.description} />}

      {seo.image && <meta name="twitter:image" content={seo.image} />}

      <link rel="canonical" href={seo.url} />
      <meta name="twitter:label1" content="Scritto da:" />
      <meta name="twitter:data1" content={seo.autore} />

      {plant && <meta name="twitter:label2" content="Pianta:" />}
      {plant && <meta name="twitter:data2" content={plant.name} />}

      <meta name="google-site-verification" content="vRRv-8gPKm9Lu7MlUl3rE_Bm8c60reAlJQer0yaOgiU" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="color-scheme" content="light" />
      <meta name="hostname" content="www.leonifrancesco.com" />
      <meta name="expected-hostname" content="www.leonifrancesco.com" />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={`${title}, garden, plants, piante, giardino, cura delle piante, giardinaggio`} />
      <meta name="language" content="IT" />
      <meta name="rating" content="General" />
      <meta name="designer" content={seo.autore} />
      <meta name="owner" content={seo.autore} />
      <meta name="copyright" content="© Copyright 2022 Francesco Leoni" />
      <meta name="category" content="Gardening" />
      <meta name="coverage" content="Worldwide" />
      <meta name="distribution" content="Global" />
      <meta http-equiv="cache-control" content="max-age=864000" />
      <meta http-equiv="content-language" content="IT" />

      <meta property="og:locale" content="it_IT" />
      <meta property="og:site_name" content={seo.websiteName} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:alt" content={seo.title} />

      <meta name="subject" content={`${seo.websiteName} | Cura delle piante da interno e esterno`} />
      <meta name="topic" content={title} />
      <meta name="summary" content={seo.description} />

      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="FavIcon/ms-icon-144x144.png" />

      {/* <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins" /> */}
    </Helmet>
  )
}

export default Seo